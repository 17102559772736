@import '../../../../../variables.css';

.content {
    padding: 0 1.75rem 8rem 1.75rem;
}

.info {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
    margin-bottom: 1.5rem;
}

.button {
    min-height: 48px;
}

.btnSignUp {
    width: 220px;
}

.captcha {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: var(--ark-small-desktop-figma-below-1023)) {
    .content {
        padding: 1rem;
    }

    .info {
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-18-line-height);
        margin-bottom: 1rem;
    }
}


@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .cancelButton {
        display: none !important;
    }
}

@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .content {
        padding: 1rem 0.5rem;
    }

    .btnSignUp {
        width: 184px;
    }

    .btnClose {
        width: 143px;
    }
}

@import "../../../../../variables.css";

.content {
    padding-left: 2.5rem;
}

.inputContainerFocused {
    display: grid;
    grid-template-columns: 1fr 4rem;
    column-gap: 0.5rem;
}

@media (min-width: var(--ark-new-med-small-mobile-above-361)) {
    .content {
        padding-left: 0;
        margin: 0 auto;
        max-width: 24.5rem;
    }
}

@media (max-width: var(--ark-small-mobile-576)) {
    .content {
        padding: 0 1.25rem;
    }
}
@import "../../../../../variables.css";

.content {
    padding-left: 2.5rem;
}

.genderListItem {
    height: 3rem;
    display: grid;
    grid-gap: 1rem;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.25rem;
    line-height: 2.4;
    color: var(--ark-neutral-black);
    display: grid;
    grid-template-columns: 1fr 2.125rem;
    text-align: left;
}

.active {
    background-color: var(--ark-highlights-green-20);
}

.genderTickIcon {
    color: var(--ark-primary-green);
}

@media (min-width: var(--ark-new-med-small-mobile-above-361)) {

    .content {
        padding-left: 0;
        margin: 0 auto;
        max-width: 22.8rem;
    }

}

@media (max-width: var(--ark-new-med-small-mobile-360)) {
    .content {
        padding-left: 0;
    }
    .genderListItem {
        padding-left: 1.25rem;
    }
}
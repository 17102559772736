@import "../../../variables.css";

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4rem;
    width: 100%;
    background-color: var(--ark-neutral-white);
    box-shadow: 0px -25px 25px rgb(255 255 255);
}

.footer::before {
    content: ' ';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 12.5rem;
    /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 50%); */
    pointer-events: none;
}

.footer__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
}

.footer__content.twoColumns {
    grid-template-columns: 9.375rem 9.375rem;
    grid-gap: 1.5rem; /* fix for Avatars*/
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .footer {
        bottom: 3.6rem;
    }

    .footer__content, .footer__content.twoColumns {
        /* grid-template-columns: minmax(0, 1fr); */
        justify-content: center;
    }
}
/* 566px when keyboard appear on portrait mode android */
@media (min-width: 566px) and (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .footer {
        bottom: 1rem;
        padding-left: 5.625rem;
    }
}

@media (max-width: var(--ark-med-small-mobile-480)) {

    .footer__content.twoColumns {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
    }
    .footer button{
        min-width: 120px;
     }
}
@import '../../../../../variables.css';

.list {
    margin-top: var(--ark-spacing-xxs);
    width: 100%;
    max-height: calc(100% - 3rem - var(--ark-spacing-xxs));
    overflow: auto;
    display: flex;
    justify-content: center;
}

.list ul {
    max-width: 20rem;
    width: 100%;
}

.countryListItem {
    height: 3rem;
    display: grid;
    grid-template-columns: 1.75rem minmax(0, 1fr);
    grid-gap: 1rem;
    align-items: center;
    padding-left: 1.5rem;
    text-align: left;
}

.countryFlag {
    width: 1.75rem;
    font-size: 1.125rem;
    border-radius: 2px;
}

.countryName {
}

.input {
    position: relative;
}

.searchIcon {
    position: absolute;
    top: 0.75rem;
    right: 0.5625rem;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    background: none;
}

.clearIcon {
    width: 2.125rem;
    height: 2.125rem;
    position: absolute;
    top: 50%;
    transform: translateY(-45%);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    background: none;
    padding: 0;
    right: 2.125rem;
    margin-right: 5px;
}

.clearIcon svg {
    color: var(--ark-neutral-disabled);
}

.clearIcon:hover svg {
    color: var(--ark-primary-blue-figma);
}

.countryBoxItemWrapper {
    padding-left: var(--ark-spacing-s);
    padding-top: 0.6rem;
    padding-bottom: var(--ark-spacing-s);
    cursor: pointer;
}
.countryBoxItemWrapper:hover {
    background: var(--ark-highlights-green-20);
}

.listWrapper {
    border-radius: 0.625rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    overflow-y: auto;
}

.listWrapper {
    margin-top: 0.5rem;
}

.noResultsWrapper {
    background-color: var(--ark-neutral-white);
    height: 5rem;
    padding-top: var(--ark-spacing-s);
    padding-left: var(--ark-spacing-s);
    border-radius: 0.625rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    text-align: left;
}

.noResultsText {
    font-size: var(--ark-18-font-size);
    color: var(--ark-neutral-black);
    font-weight: var(--ark-font-weight-regular);
}

@media (min-width: var(--ark-new-med-small-mobile-above-361)) {
    .content {
        margin: 0 auto;
        max-width: 20rem;
    }
}

@media (max-width: var(--ark-new-med-small-mobile-above-361)) {
    .content {
        padding-left: var(--ark-spacing-m);
        padding-right: var(--ark-spacing-m);
        max-width: 20rem;
    }
}

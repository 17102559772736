@import '../../../../../../../variables.css';

.container {
    display: grid;
    grid-gap: 16px;
}

.title {
    font-size: var(--ark-30-font-size);
    line-height: var(--ark-32-line-height);
    color: var(--ark-primary-asbestos-figma);
}

.frames {
    display: flex;
    grid-gap: 35px;
    flex-wrap: wrap;
}

@media (max-width: var(--ark-small-desktop-figma-below-1023)) {
    .title {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
    }
}

@media (max-width: var(--ark-medium-mobile-figma-below-719)) {
    .frames {
        justify-content: center;
    }
}
@import '../../../variables.css';

.dropdownContainer {
    position: relative;
    width: 100%;
    font-family: inherit;
}
.dropdownButton {
    height: 3.5rem;
    width: 100%;
    background-color: transparent;
    border-radius: var(--ark-border-radius);
    border: solid 2px var(--ark-neutral-disabled);
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 0 0 1rem;
    cursor: pointer;
    appearance: none;
    font-weight: var(--ark-font-weight-bold);
    font-family: inherit;
    font-size: 1.25rem;
    line-height: 1.1;
    color: var(--ark-neutral-black);
}
.dropdownButton:hover {
    border: solid 2px var(--ark-primary-blue-figma);
}
select option {
    height: 1.5rem;
}
.dropdownArrow {
    position: absolute;
    pointer-events: none;
    top: calc(3.5rem / 2);
    right: 0.75rem;
    color: var(--ark-neutral-black);
    transform: rotate(180deg) translateY(50%);
}
.dropdownContainer:hover .dropdownArrow {
    color: var(--ark-primary-blue-figma);
}
.dropdownArrow.arrowUp {
    transform: rotate(0deg) translateY(-50%);
    color: var(--ark-primary-blue-figma);
}
.primaryItem {
    font-size: 1.25rem;
    font-weight: var(--ark-font-weight-bold);
    line-height: 1.1;
    color: var(--ark-neutral-black);
}
.dropdownList {
    position: absolute;
    width: 100%;
    max-height: 0;
    z-index: 1;
    top: 4rem;
    transition: max-height 0.5s ease-in-out;
    overflow: auto;
    border-radius: var(--ark-border-radius);
    box-shadow: 0 0.125rem 0.25rem 0 var(--ark-black-15-color);
    background-color: var(--ark-neutral-white);
}
.dropdownListVisible {
    max-height: 18.75rem;
    background-color: var(--ark-neutral-white);
    border-top-left-radius: var(--ark-border-radius);
    border-top-right-radius: var(--ark-border-radius);
}
.dropdownItem {
    display: flex;
    font-size: 1.125rem;
    line-height: 2.67;
    color: var(--ark-neutral-black);
    font-weight: var(--ark-font-weight-regular);
    margin: 0;
    padding: 0 1.25rem;
    width: 100%;
}
.dropdownItem:hover {
    background-color: var(--ark-highlights-green-20);
    cursor: pointer;
}
.screenReaderOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
}

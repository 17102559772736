@import "../../../../variables.css";

.saveButton {
  background-color: var(--ark-primary-green);
  width: 4rem;
  height: 3rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none;
}

.disabled {
  background-color: var(--ark-neutral-disabled);
}

.tickIcon {
  color: var(--ark-neutral-white);
}
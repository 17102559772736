@import "../../../../../variables.css";

.birthdayPanel {}

.content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    display: grid;
    grid-template-rows: repeat(3, 3.5rem);
    grid-row-gap: 1.5rem;
}

@media (min-width: var(--ark-new-med-small-mobile-above-361)) {
    .content {
        padding-left: 0;
        margin: 0 auto;
        max-width: 20rem;
    }
}

@media (max-width: var(--ark-new-med-small-mobile-360)) {
    .content {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}
@import "../../../../../variables.css";

.content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 4.5625rem;
    display: flex;
    justify-content: center;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4rem;
    width: 100%;
}

.footer::before {
    content: ' ';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 12.5rem;
    /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 50%); */
    pointer-events: none;
}

.footer__content {
    display: grid;
    grid-template-columns: 9.375rem 9.375rem;
    grid-gap: 3.75rem;
}


.tickIcon {
    color: var(--ark-neutral-white);
}

.inputContainer {
    width: 23.75rem
}

.inputContainerFocused {
    display: grid;
    grid-template-columns: 1fr 4rem;
    column-gap: 0.5rem;
}

.saveButton {
    background-color: var(--ark-primary-green);
    width: 4rem;
    height: 3rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hide {
    display: none;
}

@media (min-width: var(--ark-new-med-small-mobile-above-361)) {
    .content {
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto;
        max-width: 23.75rem;
    }
}

@media (max-width: var(--ark-new-med-small-mobile-360)) {
    .content {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .footer {
        bottom: 3.8rem;
    }

    .footer__content  {
        grid-template-columns: minmax(0, 1fr);
        justify-content: center;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .footer {
        bottom: 1rem;
    }
}

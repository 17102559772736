@import "../../../../../variables.css";

.container{
    display: flex;
    height: 100%;
}
.avatarNavBtn{
    justify-content: center;
    font-size: var(--ark-24-font-size);
    font-weight: var(--ark-font-weight-regular);
    line-height: var(--ark-30-line-height);
    height: 100%;
    width: 306px;
    min-width: inherit;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .avatarNavBtn{
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
    }
    .avatarNavBtn::before{
        top: 0;
        height: 6px;
    }
}

@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .avatarNavBtn{
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-20-line-height);
    }
}
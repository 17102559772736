@import '../../../../../../../variables.css';

.container {
    display: grid;
    grid-gap: 16px;
}

.title {
    font-size: var(--ark-30-font-size);
    line-height: var(--ark-32-line-height);
    color: var(--ark-primary-black-figma);
}

.colorPicker {
    display: flex;
    grid-gap: 4px;
    max-width: 395px;
    flex-wrap: wrap;
}

.screenReaderOnly {
    transform: scale(0);
    position: absolute;
}

input:focus-visible + label {
    outline: var(--focus-state-outline-width) dashed var(--ark-focus-figma);
    outline-offset: 3px;
}

.colorItem {
    display: block;
    width: 52px;
    height: 52px;
    position: relative;
    border-radius: 8px;
    border: 2px solid var(--ark-primary-white-figma);
}

.colorItem.selected {
    box-shadow: 0 1px 10px var(--ark-primary-royal-blue-figma-25);
}
.colorItemWrapper {
    position: relative;
}
.colorItemWrapper:last-child .colorItem {
    border: 1px solid rgba(196, 196, 196, 1);
    width: 48px;
    height: 48px;
    margin-top: 2px;
    margin-left: 2px;
}

.colorItemWrapper:hover .colorItem {
    border: 2px solid var(--ark-primary-white-figma) !important;
    box-shadow: 0 1px 10px var(--ark-primary-royal-blue-figma-25);
}

.colorItemWrapper:hover .colorItem::after {
    content: ' ';
    width: 14px;
    height: 14px;
    position: absolute;
    background-color: var(--ark-primary-white-figma);
    opacity: 0.5;
    top: 18px;
    left: 17px;
    border-radius: 7px;
}

.hoverItem {
    width: 58px;
    height: 58px;
    border-width: 2px;
    border-style: solid;
    position: absolute;
    top: -3px;
    left: -3px;
    border-radius: 11px;
}

@media (max-width: var(--ark-small-desktop-figma-below-1023)) {
    .title {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
    }
}

@media (max-width: var(--ark-medium-mobile-figma-below-719)) {
    .colorPicker {
        justify-self: center;
    }
}
@import "../../../variables.css";

.container {
    display: block;
}
.wrapper{
    display: block;
}

.defaultAvatars{
    display: grid;
    grid-template-columns: repeat(3, var(--ark-avatar-medium-list-item));
    float: left;
    grid-gap: 0.625rem;
    margin-right: 0.625rem;
}
.premiumAvatars{
    display: grid;
    grid-template-columns: repeat(3, var(--ark-avatar-medium-list-item));
    grid-gap: 0.625rem;
}

.signUpAvatars{
    display: grid;
    grid-template-columns: repeat(6, var(--ark-avatar-medium-list-item));
    float: left;
    grid-gap: 0.625rem;
    margin-right: 0.625rem;
}

.avatar {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--ark-avatar-medium-list-item);
    height: var(--ark-avatar-medium-list-item);
    border-radius: 50%;
    border: 1px solid transparent;
    padding: 0.562rem;
    padding: 0;
    cursor: pointer;
}

.avatar__content {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--ark-avatar-background-blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.avatar__content img {
    width: 5.062rem;
    object-fit: cover;
}

.avatar_selected {
    border: 1px solid green;
}

.avatar_selected .avatar__content {
    box-shadow: 0 20px 20px 0 rgba(0, 163, 113, 0.5);
}

@media (max-width: var(--ark-small-desktop-figma-below-1023)) {
    .container{
        display: flex;
        justify-content: center
    }
    .wrapper{
        display: inline-block;
    }
    .premiumAvatars{
        grid-template-columns: repeat(2, var(--ark-avatar-medium-list-item));
    }
}
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .defaultAvatars,.premiumAvatars{
        grid-gap: 0;
    }
    .defaultAvatars{
        grid-template-columns: repeat(2, var(--ark-avatar-small-list-item));
        margin-right: 0;
    }
    .premiumAvatars{
        grid-template-columns: repeat(1, var(--ark-avatar-small-list-item));
    }

    .signUpAvatars{
        grid-template-columns: repeat(3, 6rem);
        margin-right: 0rem;
        grid-gap: 0;
    }
}

@import "../../../variables.css";

.root {
    position: relative;
}

.inputField {
    width: 100%;
    height: 3rem;
    border: 1px solid var(--ark-neutral-black);
    border-radius: .625rem;
    box-shadow: none;
    padding: 0 1.8rem 0 1rem;
    font-size: 1.125rem;
    font-weight: 400;
    color: rgba(8, 8, 8, 1);
}

.inputField:hover {
    border-color: var(--ark-primary-blue-figma);
    border-width: 2px;
}

.inputField:focus {
    border-color: var(--ark-primary-blue-figma);
    border-width: 2px;
}

.inputField::placeholder {
    opacity: .45;
    font-size: 1.125rem;
    color: var(--ark-neutral-black);
    font-weight: 700;
}

.inputWrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.warningIcon {
    position: absolute;
    right: -1.7rem;
}

.errorMessage {
    color: var(--ark-primary-red);
    font-size: 14px;
    padding-top: 0.2rem;
    text-align: left;
    font-weight: var(--ark-font-weight-bold);
    position: absolute;
}

.invalidInput, .inputField:focus.invalidInput {
    border-color: var(--ark-primary-red);
}

.clearIcon, .showIcon {
    width: 2.125rem;
    height: 2.125rem;
    position: absolute;
    top: 50%;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    background: none;
    padding: 0;
    right: 0;
}

.clearIcon {
    transform: translateY(-45%);
}

.showIcon {
    transform: translateY(-49%);
}

.clearIcon svg, .showIcon svg {
    color: var(--ark-neutral-disabled);
}

.clearIcon:hover svg {
    color: var(--ark-primary-blue-figma);
}

.searchInputClearIcon {
    right: 2.125rem;
}

.filled {
    border-color: var(--ark-primary-green);
}

@media(max-width: 1024px) {
    .warningIcon {
        display: none;
    }
}